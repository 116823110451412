import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SEO_TEXT } from '../constants';
import { SEO, OpportunityFirstSection, OpportunitySecondSection, OpportunityThirdSection } from '../components';
import { SanityOpportunityType } from '../utils/globalTypes';
import { getClient } from '../utils/sanity.client';
import { opportunitiesQuery } from '../utils/querys/opportunities/query';

interface Props {
  data: {
    sanityOpportunities: SanityOpportunityType;
  };
}

const Opportunities = ({ data: { sanityOpportunities } }: Props) => {
  const [opportunities, setOpportunities] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(opportunitiesQuery);
      setOpportunities(data);
    };
    if (opportunities === undefined && url.search === '?preview_mode=true') getData();
  }, [opportunities]);
  return (
    <Fragment>
      <SEO title={sanityOpportunities.seo?.title || ''} description={sanityOpportunities.seo?.description || ''} />
      <OpportunityFirstSection
        content={opportunities ? opportunities.firstSection : sanityOpportunities.firstSection}
      />
      <OpportunitySecondSection
        content={opportunities ? opportunities.secondSection : sanityOpportunities.secondSection}
      />
      <OpportunityThirdSection
        content={opportunities ? opportunities.thirdSection : sanityOpportunities.thirdSection}
      />
    </Fragment>
  );
};

export default Opportunities;

export const pageQuery = graphql`
  query {
    sanityOpportunities {
      seo {
        title
        keywords
        description
      }
      firstSection {
        title
        description
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      secondSection {
        title
        accordionItem {
          title
          _rawDescription
        }
        _rawContactUs
      }
      thirdSection {
        title
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
        _rawDescription
      }
    }
  }
`;
