export const opportunitiesQuery = `// groq
*[_type == 'opportunities'][0] {
  seo {
    title,
    keywords,
    description
  },
  firstSection {
    title,
    description,
    mainImage {
      asset-> {
        url
      }
    }
  },
  secondSection {
    title,
    accordionItem[] {
      title,
      "_rawDescription": description
    },
    "_rawContactUs": contactUs
  },
  thirdSection {
    title,
    mainImage {
      asset-> {
        url
      }
    },
    "_rawDescription": description
  }
}

`;
